.hover:hover .hover-hide {
  opacity: 0;
}

.hover:hover .hover-show {
  opacity: 1;
}

.hover-hide {
  opacity: 1;
}

.hover-show {
  opacity: 0;
}
